import React, { useContext, useState } from 'react'

import HelperText from '../layouts/HelperText'
import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'
import { NotificationContext } from 'state/providers/NotificationProvider'
import { checkMark } from 'utils/forms'
import { emailIsValidAndAvailable } from 'utils/login'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

type Props = {
  disabled?: boolean
  oldEmail?: string
  email: string
  setEmail: (value: string) => void
  emailInvalidOrUnavailable?: boolean | undefined
  setEmailInvalidOrUnavailable?: (valid: boolean) => void
  label?: string
  checkifAvailable?: boolean
}
export const EmailField = (props: Props) => {
  const {
    checkifAvailable,
    disabled,
    email,
    emailInvalidOrUnavailable,
    oldEmail,
    setEmail,
    setEmailInvalidOrUnavailable,
  } = props
  const [emailHelperText, setEmailHelperText] = useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [label] = useState<string>(
    typeof props.label !== 'undefined'
      ? props.label
      : 'Syötä sähköpostiosoite *'
  )

  const { setNotification } = useContext(NotificationContext)

  async function handleEmailInputChange(value: string): Promise<void> {
    setEmail(value.trim())
    if (!setEmailInvalidOrUnavailable) {
      return
    }
    if (!value.trim().length) {
      setEmailInvalidOrUnavailable(false)
      setEmailHelperText('')
    } else if (value === oldEmail) {
      setEmailInvalidOrUnavailable(false)
      setEmailHelperText('Sähköposti on oikean muotoinen')
    } else {
      try {
        const availablAndValid = await emailIsValidAndAvailable(value)
        const valid = !availablAndValid.error
        const available = availablAndValid.available
        if (!valid) {
          setEmailInvalidOrUnavailable(true)
          setEmailHelperText('Sähköposti ei ole oikean muotoinen')
        } else if (checkifAvailable && !available) {
          setEmailInvalidOrUnavailable(true)
          setEmailHelperText('Sähköposti on jo käytössä')
        } else if (valid && (!checkifAvailable || available)) {
          setEmailInvalidOrUnavailable(false)
          // visually-hidden
          setEmailHelperText('Sähköposti on oikean muotoinen')
        }
      } catch (error) {
        setNotification(
          { type: 'error', message: unexpectedErrorDefaultMessage },
          5000
        )
      }
    }
  }

  return (
    <InputContainer>
      <Input
        disabled={disabled}
        id="email"
        label={
          checkMark(
            (hasFocus && (emailInvalidOrUnavailable || !email.length)) ||
              undefined
          ) + label
        }
        placeholder="Syötä sähköpostiosoite"
        value={email}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={e => handleEmailInputChange(e.target.value)}
        required
        type="email"
        error={hasFocus && (emailInvalidOrUnavailable || !email.length)}
      />
      <HelperText
        className={emailInvalidOrUnavailable ? '' : 'visually-hidden'}
        error={
          hasFocus && emailInvalidOrUnavailable
            ? emailInvalidOrUnavailable
            : false
        }
      >
        {emailHelperText}
      </HelperText>
    </InputContainer>
  )
}
