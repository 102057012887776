import React, { useContext, useEffect, useState } from 'react'
import ResetPasswordForm, {
  PasswordResetData,
} from 'components/forms/SetNewPasswordForm'

import ContentContainer from 'components/ContentContainer'
import { LoginContext } from 'state/providers/LoginProvider'
import { NotificationContext } from 'state/providers/NotificationProvider'
import { localStorageKeyNames } from 'utils/localStorage'
import { navigate } from 'gatsby'
import notForLoggedInUsers from 'utils/login/notForLoggedInUsers'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

const SetNewPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [resetPasswordToken, setResetPasswordToken] = useState<string | null>(
    null
  )
  const [email, setEmail] = useState<string | null>(null)
  const { isLoggedIn, changeLoggedOutCustomerPassword } =
    useContext(LoginContext)
  const { setNotification } = useContext(NotificationContext)

  const handlePasswordReset = async (data: PasswordResetData) => {
    setLoading(true)
    if (data.email && data.newPassword && resetPasswordToken) {
      try {
        const response = await changeLoggedOutCustomerPassword(
          data.email,
          data.newPassword,
          resetPasswordToken
        )
        const { success, message } = response
        setNotification(
          {
            type: success ? 'success' : 'error',
            message,
          },
          5000
        )
      } catch (err) {
        setLoading(false)
        setNotification(
          {
            type: 'error',
            message: unexpectedErrorDefaultMessage,
          },
          5000
        )
        console.error('Customer login failed', err)
        return
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    const emailInLocalStorage =
      localStorage.getItem(localStorageKeyNames.customerEmail) ?? ''
    localStorage.removeItem('')
    const params = new URLSearchParams(window.location.search)
    const tokenParam = params.get('token')
    setEmail(emailInLocalStorage)
    tokenParam && setResetPasswordToken(tokenParam)
    removeUrlParams()
    !tokenParam && navigate('/password')
  }, [])

  useEffect(() => {
    notForLoggedInUsers(isLoggedIn, '/profile')
  }, [isLoggedIn])

  return (
    <ContentContainer>
      <ResetPasswordForm
        handlePasswordReset={data => handlePasswordReset(data)}
        email={email}
        heading="Resetoi salasana"
        loading={loading}
      />
    </ContentContainer>
  )
}

// TODO: create hook to get and clear url params
function removeUrlParams() {
  typeof window !== 'undefined' &&
    window.history.pushState(
      window.history.state,
      document.title,
      window.location.origin + window.location.pathname
    )
}

export default SetNewPassword
