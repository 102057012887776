import React, { useState } from 'react'
import passwordIsValid, { showPasswordError } from 'utils/login/passwordIsValid'

import HelperText from '../layouts/HelperText'
import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'
import { checkMark } from 'utils/forms'

type Props = {
  disabled?: boolean
  newPassword: string
  setNewPassword: (value: string) => void
}
export const NewPasswordField = ({
  disabled,
  newPassword,
  setNewPassword,
}: Props) => {
  const [newPasswordHelperText, setNewPasswordHelperText] = useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  function handleNewPasswordInputChange(value: string) {
    setNewPassword(value)
    if (value.length === 0) {
      setNewPasswordHelperText('')
    } else if (value.length < 8) {
      setNewPasswordHelperText('Salasanan on oltava vähintään 8 merkkiä pitkä')
    } else if (!passwordIsValid(value)) {
      setNewPasswordHelperText(
        'Salasanan pitää sisältää isoja ja pieniä kirjaimia, sekä numeroita tai erikoismerkkejä'
      )
    } else {
      // visually-hidden
      setNewPasswordHelperText('Salasana on oikean muotoinen')
    }
  }

  return (
    <InputContainer>
      <Input
        required
        id="new-password"
        label={
          checkMark(hasFocus ? showPasswordError(newPassword) : undefined) +
          'Uusi salasana *'
        }
        placeholder="Syötä uusi salasana"
        value={newPassword}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={e => handleNewPasswordInputChange(e.target.value)}
        type="password"
        disabled={disabled}
        error={hasFocus && showPasswordError(newPassword)}
      />
      <HelperText
        className={
          !newPassword.length || !showPasswordError(newPassword)
            ? 'visually-hidden'
            : ''
        }
        error={hasFocus && newPasswordHelperText.length > 0}
      >
        {newPasswordHelperText}
      </HelperText>
    </InputContainer>
  )
}
