import styled from 'styled-components'

export default styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr;
  font-weight: 400;
  font-size: 18x;
  line-height: 24px;
  margin-block-end: 16px;
`
