/**
 *
 * @param password
 * @returns true if password is valid, false if password is not valid
 */
function passwordIsValid(password: string): boolean {
  const specialChars = password.match(/[^a-zA-Z0-9]/g)?.length ?? 0
  const upperCase = password.match(/[A-Z]/g)?.length ?? 0
  const lowerCase = password.match(/[a-z]/g)?.length ?? 0
  const digits = password.match(/[0-9]/g)?.length ?? 0
  const differentTypes = [specialChars, upperCase, lowerCase, digits].reduce(
    (prev: number, curr: number): number => {
      return curr > 0 ? prev + 1 : prev
    },
    0
  )
  return differentTypes >= 3 && password.length >= 8
}

export const showPasswordError = (password: string) =>
  !passwordIsValid(password)

export const showRepeatPasswordError = (
  newPassword: string,
  repeatNewPassword: string
) => newPassword !== repeatNewPassword

export default passwordIsValid
