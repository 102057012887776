import { navigate } from 'gatsby'

/**
 * Redirects to the given url or to '/' if isLoggedIn
 * */
function notForLoggedInUsers(
  isLoggedIn?: boolean,
  redirectToUrl?: string
): false | Promise<void> | undefined {
  return isLoggedIn && navigate(redirectToUrl ?? '/')
}

export default notForLoggedInUsers
