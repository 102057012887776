import styled from 'styled-components'

export default styled.main`
  width: 100%;
  max-width: var(--content-container-max-width);
  margin-inline: auto;
  padding-inline: var(--content-container-inline-padding);
  margin-block-start: calc(var(--content-container-inline-padding) * 1.5);
  display: grid;
  gap: var(--content-container-gap);

  & > p,
  h1 {
    text-align: center;
    max-width: 640px;
    margin-inline: auto;
  }
`
