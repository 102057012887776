import { navigate } from 'gatsby'

/**
 * Redirects to '/login' if !isLoggedIn
 * @param isLoggedIn
 * @returns false if !isNotlogged in, void if isLoggedIn
 */
function onlyForLoggedInUsers(isLoggedIn?: boolean): false | Promise<void> {
  return !isLoggedIn && navigate('/login')
}

export default onlyForLoggedInUsers
