import React, { useState } from 'react'

import HelperText from '../layouts/HelperText'
import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'
import { checkMark } from 'utils/forms'
import { showRepeatPasswordError } from 'utils/login/passwordIsValid'

type Props = {
  disabled?: boolean
  newPassword: string
  repeatNewPassword: string
  setRepeatNewPassword: (value: string) => void
}
export const RepeatNewPasswordField = ({
  disabled,
  newPassword,
  repeatNewPassword,
  setRepeatNewPassword,
}: Props) => {
  const [repeatNewPasswordHelperText, setRepeatNewPasswordHelperText] =
    useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  function handleRepeatNewPasswordInputChange(value: string) {
    setRepeatNewPassword(value)
    const passwordsMatch = newPassword === value
    if (newPassword.length && !passwordsMatch) {
      setRepeatNewPasswordHelperText('Salasanat eivät täsmää')
    } else if (newPassword.length && passwordsMatch) {
      // visually-hidden
      setRepeatNewPasswordHelperText('Salasanat täsmävät')
    } else {
      setRepeatNewPasswordHelperText('')
    }
  }

  return (
    <InputContainer>
      <Input
        id="repeat-new-password"
        label={
          checkMark(
            hasFocus
              ? showRepeatPasswordError(newPassword, repeatNewPassword)
              : undefined
          ) + 'Vahvista uusi salasana *'
        }
        placeholder="Syötä salasana uudestaan"
        value={repeatNewPassword}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={e =>
          handleRepeatNewPasswordInputChange(e.target.value.trim())
        }
        required
        type="password"
        disabled={disabled}
        error={
          hasFocus && showRepeatPasswordError(newPassword, repeatNewPassword)
        }
      />
      <HelperText
        className={
          !repeatNewPassword.length ||
          !showRepeatPasswordError(newPassword, repeatNewPassword)
            ? 'visually-hidden'
            : ''
        }
        error={hasFocus && repeatNewPasswordHelperText.length > 0}
      >
        {repeatNewPasswordHelperText}
      </HelperText>
    </InputContainer>
  )
}
