import { Button, H3 } from '@mehilainen/design-system'
import React, { useEffect, useState } from 'react'

import { EmailField } from './fields/EmailField'
import { Link } from 'gatsby'
import { NewPasswordField } from './fields/NewPasswordField'
import { RepeatNewPasswordField } from './fields/RepeatNewPasswordField'
import { passwordIsValid } from 'utils/login'
import styled from 'styled-components'

type Props = {
  handlePasswordReset: (data: PasswordResetData) => void
  heading?: string
  email?: string | null
  loading: boolean
}

export default (props: Props) => {
  const [newPassword, setNewPassword] = useState<string>('')
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { handlePasswordReset, heading, loading } = props
  const disabled =
    !newPassword ||
    !passwordIsValid(newPassword) ||
    newPassword.trim() !== repeatNewPassword.trim()

  useEffect(() => {
    props.email && setEmail(props.email)
  }, [props.email])

  return (
    <SetNewPassword>
      {heading && <H3>{heading}</H3>}
      <EmailField email={email} setEmail={setEmail} />
      <NewPasswordField
        newPassword={newPassword}
        setNewPassword={setNewPassword}
      />
      <RepeatNewPasswordField
        repeatNewPassword={repeatNewPassword}
        setRepeatNewPassword={setRepeatNewPassword}
        newPassword={newPassword}
      />
      <Buttons>
        <Button
          large
          disabled={disabled}
          loading={loading}
          onClick={e => {
            e.preventDefault()
            handlePasswordReset({ newPassword, email })
          }}
        >
          Tallenna
        </Button>
      </Buttons>
      <Link to={'/login'}>Siirry sisäänkirjautumissivulle</Link>
    </SetNewPassword>
  )
}

export type PasswordResetData = {
  newPassword: string
  email: string
}

const SetNewPassword = styled.form`
  display: grid;
  grid-template-columns: minmax(min(90vw, 388px), min(90vw, 582px));
  justify-content: center;
  gap: 16px 0;
`
const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`
