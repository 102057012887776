import * as loginService from 'requests/loginRequests'

export const emailAvailable = async (email: string): Promise<boolean> => {
  const response = await loginService.isEmailAvailable(email)
  return response.data.isEmailAvailable.is_email_available
}

/**
 *
 * @param email
 * @returns error message and available === false if invalid, otherwise available (true | false)
 */
export const emailIsValidAndAvailable = async (
  email: string
): Promise<EmailValidation> => {
  const response = await loginService.isEmailAvailable(email)
  if (response.errors) {
    const error = response.errors[0].message
    return {
      error,
      available: false,
    }
  } else if (response.data) {
    return {
      available: response.data.isEmailAvailable.is_email_available,
    }
  }
  return {
    available: false,
  }
}

export type EmailValidation = {
  error?: string
  available: boolean
}
